import type { Dispatch, FC, ReactNode, SetStateAction } from 'react'
import { createContext, useContext, useState } from 'react'

export type NavbarVariant = 'collapsed' | 'expanded' | 'hidden-cta'

const NavbarContext = createContext<{
  variant: 'collapsed' | 'expanded' | 'hidden-cta'
  setVariant: Dispatch<SetStateAction<NavbarVariant>>
} | null>(null)

export const NavbarContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [variant, setVariant] = useState<NavbarVariant>('expanded')

  return (
    <NavbarContext.Provider value={{ variant, setVariant }}>
      {children}
    </NavbarContext.Provider>
  )
}

export const useNavbarContext = () => {
  const ctx = useContext(NavbarContext)

  if (!ctx) {
    throw new Error(
      'useNavbarContext has to be used within <NavbarContextProvider>'
    )
  }

  return ctx
}
